<!--店铺概况 -->
<template>
  <div>
    <div class="store">
      <div class="store__info">
        <div class="store__info__top">
          <img
          class="store__info__img"
          src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/mp/dptx.png"
          alt=""
          />
          <div class="store__info__right">
            <p class="store__info__name commo-ellipsis">{{ distributorVO.shopName }}</p>
            <p class="store__info__mobile">{{ distributorVO.contactPhone }}</p>
          </div>
        </div>
        <div class="store__info__bottom">
          <div class="store__account__item">
            <p class="store__account__title">返利金额（元）</p>
            <p
              @click="
                jumpLink('/syoung/shop/rebate-list', 'virtualCredit', [merchantFundVO.virtualCredit, merchantFundVO.invalidVirtualCredit, merchantFundVO.imminentInvalidVirtualCredit])
              "
              class="store__account__money--mid store__account__money--link"
            >
              {{ formaNumber(merchantFundVO.virtualCredit, { type: 'thousands' }) }}
            </p>
          </div>
          <div class="store__account__item">
            <p class="store__account__title">优惠券（张）</p>
            <p class="store__account__money--mid">{{ formaNumber(merchantFundVO.couponNum, { type: 'thousands' }) }}</p>
          </div>
        </div>
      </div>
      <div class="store__account">
        <div class="store__account__top">
          <div class="store__account__top__left">
            <p class="store__account__cont">
              <span class="store__account__illustrate">自有仓商品（元）</span>
              <el-tooltip class="item" effect="dark" placement="top-start" popper-class="store__account__tip">
                <div slot="content">自有仓商品资金：只能用于采购自<br/>有仓的商品，经营主体为：湖南水<br/>羊电子商务有限公司</div>
                <i class="el-icon-info icon-size"></i>
              </el-tooltip>
            </p>
            <p class="store__account__munt">经营主体：{{ merchantFundVO && merchantFundVO.selfOwnCompanyInfoVO && merchantFundVO.selfOwnCompanyInfoVO.ownCompanyName }}</p>
            <div>
              <div class="store__account__money">
                <div class="store__account__money--left">
                  <span
                  @click="
                    jumpLink(
                      '/syoung/shop/account-balance-list?ownCompanyId='+ merchantFundVO.selfOwnCompanyInfoVO.ownCompanyId,
                      'balanceAmount',
                      merchantFundVO && merchantFundVO.balanceAmountVO && merchantFundVO.balanceAmountVO.selfAmount || 0
                    )
                  "
                  class="store__account__money--big store__account__money--link"
                  >
                    {{ formaNumber(merchantFundVO && merchantFundVO.balanceAmountVO && merchantFundVO.balanceAmountVO.selfAmount || 0, { type: 'thousands' }) }}
                  </span>
                  <p class="store__account__money--cont-text">账户余额（元）</p>
                </div>
                <div class="store__account__money--right">
                  <span
                    @click="
                      jumpLink(
                        '/syoung/shop/cash-deposit-list?ownCompanyId='+ merchantFundVO.selfOwnCompanyInfoVO.ownCompanyId,
                        'depositAmount',
                        merchantFundVO && merchantFundVO.depositAmountVO && merchantFundVO.depositAmountVO.selfAmount || 0
                      )
                    "
                    class="store__account__money--big store__account__money--link"
                  >
                    {{ formaNumber(merchantFundVO && merchantFundVO.depositAmountVO && merchantFundVO.depositAmountVO.selfAmount || 0, { type: 'thousands' }) }}
                  </span>
                  <p class="store__account__money--cont-text">保证金（元）</p>
                </div>
              </div>
              <p>
                <pay @sureRefresh="sureRefresh" ref="pay">
                  <el-button @click="rechargeModeShow(merchantFundVO.selfOwnCompanyInfoVO)" size="mini" type="primary">预付款</el-button>
                  <el-button type="text" class="store__account__money--text" @click="cashout(merchantFundVO.selfOwnCompanyInfoVO)" v-if="cashoutBtnStatus === 'true'">退款</el-button>
                </pay>
              </p>
            </div>
          </div>
          <div class="store__account__top__right">
            <p class="store__account__cont">
              <span class="store__account__illustrate">合作仓商品（元）</span>
              <el-tooltip class="item" effect="dark" placement="top-start" popper-class="store__account__tip">
                <div slot="content">合作仓商品资金：只能用于采购合<br/>作仓的商品，经营主体为：长沙水<br/>羊网络科技有限公司</div>
                <i class="el-icon-info icon-size"></i>
              </el-tooltip>
            </p>
            <p class="store__account__munt">经营主体：{{ merchantFundVO && merchantFundVO.internationalOwnCompanyInfoVO && merchantFundVO.internationalOwnCompanyInfoVO.ownCompanyName }}</p>
            <div>
              <div class="store__account__money">
                <div class="store__account__money--left">
                  <span
                  @click="
                    jumpLink(
                      '/syoung/shop/account-balance-list?ownCompanyId='+ merchantFundVO.internationalOwnCompanyInfoVO.ownCompanyId,
                      'balanceAmount',
                      merchantFundVO &&merchantFundVO.balanceAmountVO && merchantFundVO.balanceAmountVO.internationalAmount || 0
                    )
                  "
                  class="store__account__money--big store__account__money--link"
                  >
                    {{ formaNumber(merchantFundVO &&merchantFundVO.balanceAmountVO && merchantFundVO.balanceAmountVO.internationalAmount || 0, { type: 'thousands' }) }}
                  </span>
                  <p class="store__account__money--cont-text">账户余额（元）</p>
                </div>
                <div class="store__account__money--right">
                  <span
                    @click="
                      jumpLink(
                        '/syoung/shop/cash-deposit-list?ownCompanyId='+ merchantFundVO.internationalOwnCompanyInfoVO.ownCompanyId,
                        'depositAmount',
                        merchantFundVO && merchantFundVO.depositAmountVO && merchantFundVO.depositAmountVO.internationalAmount || 0
                      )
                    "
                    class="store__account__money--big store__account__money--link"
                  >
                    {{ formaNumber(merchantFundVO && merchantFundVO.depositAmountVO && merchantFundVO.depositAmountVO.internationalAmount || 0, { type: 'thousands' }) }}
                  </span>
                  <p class="store__account__money--cont-text">保证金（元）</p>
                </div>
              </div>
              <p>
                <pay @sureRefresh="sureRefresh" ref="pay">
                  <el-button @click="rechargeModeShow(merchantFundVO.internationalOwnCompanyInfoVO)" size="mini" type="primary">预付款</el-button>
                  <el-button type="text" class="store__account__money--text" @click="cashout(merchantFundVO.internationalOwnCompanyInfoVO)" v-if="cashoutBtnStatus === 'true'">退款</el-button>
                </pay>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="title">交易信息</p>
    <div class="trade">
      <div class="trade__info">
        <p class="trade__num">{{ orderStatisticCO.monthOrderCount }}</p>
        <p class="trade__name"><i class="iconfont icon-benyuedindanshu"></i>本月订单数</p>
      </div>
      <div class="trade__info">
        <p class="trade__num">{{ orderStatisticCO.totalOrderCount }}</p>
        <p class="trade__name"><i class="iconfont icon-leijidindanshu"></i>累计订单数</p>
      </div>
      <div class="trade__info">
        <p class="trade__num">{{ orderStatisticCO.monthPayAmount }}</p>
        <p class="trade__name"><i class="iconfont icon-benyuedinhuojine"></i>本月订货金额（元）</p>
      </div>
      <div class="trade__info">
        <p class="trade__num">{{ orderStatisticCO.totalPayAmount }}</p>
        <p class="trade__name"><i class="iconfont icon-leijidinhuojine"></i>累计订单金额（元）</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getShopStatistic, distributorWithdrawRecordIzWithdraw } from '@/api/shop';
import pay from '@/components/Pay';
import { formaNumber } from '@/utils/date';
export default {
  name: 'StoreProfile',
  components: {
    pay,
  },
  data() {
    return {
      merchantFundVO: {}, // 账户资金
      orderStatisticCO: {}, // 交易信息
      distributorVO: {}, // 分销商信息
      type: '', // 分销商类型
      formaNumber,
      cashoutBtnStatus: 'false'
    };
  },
  mounted() {
    this.fetchShopStatistic();
  },
  methods: {
    // 跳转其他页面-账户余额、返利余额、保证金,传参数（路由，名称，值）
    jumpLink(link, name, key) {
      localStorage.setItem(name, key);
      this.$router.push(link);
    },
    // 获取店铺概况信息
    fetchShopStatistic() {
      // 是否可提现
      distributorWithdrawRecordIzWithdraw().then(res => {
        if (res.success) {
          this.cashoutBtnStatus = res.data;
        }
      });
      getShopStatistic().then(res => {
        this.userType = res.data.type;
        this.merchantFundVO = res.data.merchantFundVO || {};
        this.distributorVO = res.data.distributorVO || {};
        this.type = this.distributorVO.type;
        this.orderStatisticCO = res.data.orderStatisticCO || {};
      });
    },
    sureRefresh() {
      // 充值按钮完成 不论成功失败
      this.fetchShopStatistic();
    },
    rechargeModeShow(companyInfoVO = {}) {
      this.$refs.pay.rechargeModeShow(companyInfoVO);
    },
    // 退款
    cashout(obj = {}) {
      this.$router.push('/syoung/shop/cash-out?ownCompanyId=' + obj.ownCompanyId);
    },
  }
};
</script>

<style lang="scss" scoped>
@import './store-profile';
</style>
