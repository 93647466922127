<template>
  <!-- <div class="app-container"></div> -->
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="店铺概况" name="first">
        <StoreProfile v-if="activeName === 'first'"></StoreProfile>
      </el-tab-pane>
      <el-tab-pane label="基本信息" name="second">
        <BasicInfo v-if="activeName === 'second'"></BasicInfo>
      </el-tab-pane>
      <el-tab-pane label="合同信息" name="third">
        <BargainInfo v-if="activeName === 'third'"></BargainInfo>
      </el-tab-pane>
      <!-- <el-tab-pane label="已授权品牌" name="fourth">
        <BrandInfo v-if="activeName === 'fourth'"></BrandInfo>
      </el-tab-pane> -->

      <el-tab-pane label="白名单店铺" name="white">
        <WhiteList v-if="activeName === 'white'"></WhiteList>
      </el-tab-pane>
      <el-tab-pane label="品牌授权" name="authorization">
        <BrandAuthorization v-if="activeName === 'authorization'"></BrandAuthorization>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import StoreProfile from '@/components/views/shop/StoreProfile';
import BargainInfo from '@/components/views/shop/BargainInfo';
import BasicInfo from '@/components/views/shop/BasicInfo';
import BrandInfo from '@/components/views/shop/BrandInfo';
import BrandAuthorization from '@/components/views/shop/BrandAuthorization';
import WhiteList from '@/components/views/shop/WhiteList';
import eventReporter from '@/utils/event-reporter';
import { mapActions } from 'vuex';
export default {
  components: {
    StoreProfile,
    BargainInfo,
    BasicInfo,
    BrandInfo,
    BrandAuthorization,
    WhiteList,
  },
  data() {
    return {
      activeName: 'first',
    };
  },
  created() {
    const { activeName } = this.$route.query;
    if (activeName) this.activeName = activeName;
  },
  mounted() {
    this.GetAuthenticateInfo(); // 校验下账号权限、判断冻结、注销
  },
  computed: {},
  methods: {
    ...mapActions(['GetAuthenticateInfo']),
    handleClick() {
      if (this.activeName === 'authorization') {
        eventReporter.trackClick(this.$route, {
          name: '品牌授权-查看',
          event_source: 'query_license_page',
        });
      }
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
</style>
