<template>
  <div class="modify">
    <el-form
      :inline-message="true"
      :model="ruleForm"
      :rules="rules"
      label-width="150px" 
      ref="ruleForm"
    >
      <el-form-item label="选择平台" prop="platform">
        <el-select v-model="ruleForm.platform" filterable placeholder="请选择平台" @change="changeInput">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="ruleForm.platform === 'TAOBAO' ||  ruleForm.platform === 'TMALL' ? '旺旺ID' : '平台店铺ID'" prop="platformShopId" 
       :rules="[
            {
              required: ruleForm.platform === 'TAOBAO' ||  ruleForm.platform === 'TMALL' ? true : false,
              message: '淘系必填信息',
              trigger: 'blur',
            },
          ]">
        <el-input v-model="ruleForm.platformShopId" :placeholder="ruleForm.platform === 'TAOBAO' ||  ruleForm.platform === 'TMALL'  ? '请填写旺旺ID' : '请填写平台店铺ID'"></el-input>
      </el-form-item>
      <el-form-item label="平台店铺名称" prop="platformShopName">
        <el-input v-model="ruleForm.platformShopName" placeholder="请填写平台店铺名称"></el-input>
      </el-form-item>
      <el-form-item label="店铺网址" prop="shopWebsite">
        <el-input v-model="ruleForm.shopWebsite" placeholder="请填写店铺网址"></el-input>
      </el-form-item>
      <p class="tips">注:请填写正确的店铺链接，防止被品牌方打假</p>
      <el-form-item>
        <el-button
          :loading="saveLoading"
          @click="submitForm('ruleForm')"
          class="modify__btn--save"
          type="primary"
          >确 定</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { whiteListCreate } from '@/api/shop';
import { dictListByType } from '@/api/brandauthorization';
export default {
  name: 'modify',
  data() {
    return {
      options: [],
      ruleForm: {
       platform: '',
       platformShopId: '',
       platformShopName: '',
       shopWebsite: ''
      },
      rules: {
        platform: [
          { required: true, message: '请选择平台', trigger: 'blur' }
        ],
         platformShopName: [
          { required: true, message: '请填写平台店铺名称', trigger: 'blur' }
        ],
         shopWebsite: [
          { required: true, message: '请填写店铺网址', trigger: 'blur' }
        ]
      },
      saveLoading: false
    };
  },
  created() {
    this.getType();
  },
  methods: {
    changeInput(val){
      if(val !== 'TMALL' && val !== 'TAOBAO'){
        this.$refs.ruleForm.clearValidate('platformShopId');
      }
    },
    async getType() {
      const data = await dictListByType('soyoungzg_channel_online');
      if (data.code === '0') {
        this.options = data.data || [];
      }
    },
    submitForm(formName) {
      const postData = { ...this.ruleForm };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          whiteListCreate(postData)
            .then((res) => {
              if (res.code === '0') {
                this.$message.success('操作成功');
                this.$emit('submit');
              }
            })
            .finally(() => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.modify {
  /deep/ .el-form {
    width: 648px;
    margin: 0 auto;
    .el-input {
      width: 350px;
    }
  }
  &__btn {
    &--save {
      background: #AB0033;;
      border-radius: 5px;
      font-size: 18px;
      border-color: #AB0033;;
      width: 250px;
      color: #fff;
      margin: 30px 0 0 50px;
    }
  }
}
.tips{
  margin-left: 150px;
}
</style>