<!-- 提交成功卡片 -->
<template>
  <div class="success-card">
    <img :src="a" class="ico" />
    <p class="txt">成功提交品牌授权申请，请耐心等待审核</p>
    <ButtonHoc type="primary" @click="toBack">返回</ButtonHoc>
  </div>
</template>

<script>
export default {
  data() {
    return {
      a: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/brandAuthorization-waiting.png',
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    toBack() {
      return this.$listeners.toApply('listComponent');
    },
  },
};
</script>
<style lang='scss' scoped>
.success-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
  padding-bottom: 100px;
  .ico {
    width: 72px;
    height: 72px;
  }
  .txt {
    margin: 42px 0 117px 0;
    font-size: 18px;
    color: #606060;
  }
  .el-button {
    width: 150px;
    height: 36px;
    font-size: 18px;
  }
}
</style>