<template>
  <div class="wrap">
    <div class="tips">
      <p>合同说明</p>
      <p>为了保证店铺能够正常采货，请完成合同的签署；</p>
      <p>
        合同的签署需要在当前页面(合同信息-合同列表中)，或者在水羊直供小程序里面进入【个人中心-店铺信息】里面填写完合同信息后，系统会发送电子合同的短信到您的注册手机号码，点击短信链接完成合同的签署即可，签署完合同，合同列表就会显示合同签署的信息；
      </p>
    </div>
    <!-- <div class="title">合同信息（以下信息用于合同签署）</div>
    <div class="content">
      <div class="content__left">
        <div class="content__row">
          <span class="content__name">店铺名称：</span><span>{{ shopName }}</span>
        </div>
        <div class="content__row">
          <span class="content__name">真实姓名：</span
          ><span>{{ contractInfoVO.contactRealName }}</span>
        </div>
        <div class="content__row">
          <span class="content__name">身份证号：</span
          ><span>{{ contractInfoVO.contactIdcardNo }}</span>
        </div>
      </div>
      <div class="content__right">
        <div class="content__row">
          <span class="content__name">联系电话：</span
          ><span>{{ contractInfoVO.contactPhone }}</span>
        </div>
        <div class="content__row">
          <span class="content__name">联系邮箱：</span
          ><span>{{ contractInfoVO.contactEmail }}</span>
        </div>
        <div class="content__row">
          <span class="content__name">联系地址：</span
          ><span>{{ contractInfoVO.contactAddress }}</span>
        </div>
      </div>
    </div> -->
    <el-table
      :data="distributorContractList"
      style="width: 100%"
      border
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column align="center" label="合同名称" prop="contractName"></el-table-column>
      <el-table-column align="center" label="合同类型" prop="typeName"></el-table-column>
      <el-table-column align="center" label="授权渠道" prop="authChannelNames">
        <template slot-scope="scope">{{ scope.row.authChannelNames.join() }}</template>
      </el-table-column>
      <el-table-column align="center" label="公司名称" prop="company"></el-table-column>
      <el-table-column align="center" label="合同有效期" prop="typeName">
        <template slot-scope="scope"
          >{{ scope.row.startDate | parseTime('{y}-{m}-{d}') }}至{{
            scope.row.endDate | parseTime('{y}-{m}-{d}')
          }}</template
        >
      </el-table-column>
      <el-table-column align="center" label="合同状态" prop="statusName"></el-table-column>
      <el-table-column align="center" label="操作" width="120px">
        <template slot-scope="scope">
          <el-button
            @click="getContract(scope.row.contractUrl)"
            type="text"
            v-if="scope.row.signStatus === 'FINISH' && scope.row.contractUrl"
            >查看合同</el-button
          >
          <el-button
            @click="getContract(scope.row.shortSignUrl)"
            type="text"
            v-if="scope.row.signStatus === 'WAITING'"
            >签署合同</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getContractInfo } from '@/api/shop';
export default {
  name: 'BasicInfo',
  data() {
    return {
      contractInfoVO: {}, // 合同信息
      shopName: '', // 店铺名称
      distributorContractList: [], // 合同管理列表
      headerCellStyle: { backgroundColor: '#F3F3F3', fontWeight: '400', fontSize: '12px' },
      headerRowStyle: { color: '#333333' }
    };
  },
  mounted() {
    this.getContractInfo();
  },
  methods: {
    getContractInfo() {
      getContractInfo(this.contractQuery).then((res) => {
        this.contractInfoVO = res.data.contractInfoVO || {};
        this.shopName = res.data.shopName || '';
        this.distributorContractList = res.data.distributorContractList || [];
      });
    },
    getContract(url) {
      window.open(url);
    }
  }
};
</script>
<style lang="scss" scoped>
@import './bargain-info';
</style>