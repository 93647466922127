<!-- 填写授权资料 -->
<template>
  <div>
    <component :ref="name" :is="componentName"></component>
  </div>
</template>

<script>
import myFormShop from './shop/myForm.vue';
import myFormNoShop from './noshop/myForm.vue';
export default {
  data() {
    return {
      componentName: myFormShop,
      name: 'myFormShop',
    };
  },
  inject: ['getInitData'],
  components: { myFormShop, myFormNoShop },

  computed: {},

  created() {
    const { shopTypeValue } = this.getInitData();
    if (shopTypeValue === 'STORELESS') {
      this.componentName = myFormNoShop;
      this.name = 'myFormNoShop';
    }
  },

  mounted() {},

  methods: {
    // 外部调用
    next(cd) {
      this.$refs[this.name].next(cd);
    },
  },
};
</script>
<style lang='scss' scoped>
</style>