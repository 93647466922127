<!-- 申请品牌授权 -->
<template>
  <div>
    <AtepsBox
      :active="active"
      :stepList="stepList"
      @toBack="toBack"
      @toNext="toNext"
      :showNextBtn="active !== 1"
      :hideBtn="active == 3"
    >
      <component
        :ref="componentName"
        v-bind="$attrs"
        v-on="$listeners"
        :is="componentName"
        :toNext="toNext"
      ></component>
    </AtepsBox>
  </div>
</template>

<script>
import chacnnelChange from './chacnnel-change.vue';
import brandList from './brand-list.vue';
import AtepsBox from '@/components/AtepsBox';
import theForm from './theForm';
import successCard from './successCard';
export default {
  data() {
    return {
      active: 0,
      stepList: [
        {
          component: 'chacnnel-change', // 组件名称
          stepTitle: '选择授权渠道', // 步骤名称
        },
        { component: 'brandList', stepTitle: '选择授权品牌' },
        { component: 'theForm', stepTitle: '填写店铺信息' },
        { component: 'successCard', stepTitle: '提交成功' },
      ],
      publicData: {}, // 总数据存储参考
    };
  },
  // 父组件中返回要传给下级的数据
  provide() {
    return {
      getInitData: () => {
        return this.publicData;
      },
    };
  },
  components: { chacnnelChange, AtepsBox, brandList, theForm, successCard },
  computed: {
    componentName() {
      const name = this.stepList[this.active].component;
      return name;
    },
  },

  created() {},

  mounted() {},

  methods: {
    // 上一步
    toBack() {
      if (this.active === 0) return this.$listeners.toApply('listComponent');
      this.active--;
    },
    // 下一步
    toNext() {
      this.$refs[this.componentName].next((formLabelAlign) => {
        this.publicData = Object.assign({}, this.publicData, formLabelAlign);
        switch (this.active) {
          case 4:
            this.active = 0;
            break;
          default:
            this.active++;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
</style>