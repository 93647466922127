<template>
  <div class="app-container">
    <div class="table-container">
      <div class="btn__div">
        <el-button @click.stop="apply()" size="small" type="primary">申请加入白名单</el-button>
      </div>
      <div class="tips">
        <p>白名单说明：加入白名单的店铺，受到品牌方的保护，不会被品牌方打假；</p>
      </div>
      <el-table :data="list" empty-text="暂无数据" border v-loading.body="listLoading">
        <el-table-column align="center" label="店铺名称" prop="platformShopName"></el-table-column>
        <el-table-column align="center" label="店铺ID" prop="platformShopId"></el-table-column>        
        <el-table-column align="center" label="平台" prop="platformName"></el-table-column>
        <el-table-column align="center" label="店铺网址" prop="shopWebsite"></el-table-column>
        <el-table-column align="center" label="审核结果" prop="statusName"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageNo"
          :page-size="pageSize"
          :disabled="listLoading"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      @close="dialogVisible = false"
      append-to-body
      title="填写申请信息"
      width="900px"
    >
      <WhiteListApply v-if="dialogVisible" @submit="submit" />
    </el-dialog>
  </div>
</template>
<script>
import { whiteList } from '@/api/shop';
import WhiteListApply from '@/components/views/shop/WhiteListApply';
import eventReporter from '@/utils/event-reporter';
export default {
  data() {
    return {
      dialogVisible: false,
      list: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      listLoading: false,
    };
  },
  components: {
    WhiteListApply,
  },
  computed: {},
  created() {
    this.fetchData();
    eventReporter.trackClick(this.$route, {
      name: '白名单店铺',
      event_source: 'query_whitlelist_page',
    });
  },
  methods: {
    submit() {
      this.dialogVisible = false;
      this.fetchData();
    },
    // 申请加入白名单
    apply() {
      this.dialogVisible = true;
      eventReporter.trackClick(this.$route, {
        name: '白名单店铺-申请',
        event_source: 'create_whitlelist_page',
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      whiteList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        data: {},
      })
        .then((response) => {
          const { list, total } = response.data;
          this.list = list;
          this.total = total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    // 删除
    handleDelete(val, index) {
      // this.$confirm('确认删除吗?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   center: true,
      // })
      //   .then(() => {
      //     handleDeleteIds(val).then((response) => {
      //       this.list.splice(index, 1);
      //     });
      //     this.$message({
      //       type: 'success',
      //       message: '删除成功！',
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '已取消删除',
      //     });
      //   });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.btn__div {
  margin-bottom: 10px;
  &--mid {
    margin: 0 10px 0 20px;
    color: #AB0033;
    border: 1px solid #AB0033;
  }
}
.tips {
  width: 100%;
  padding: 10px;
  background-color: #fffaf1;
  color: #e98e5e;
  font-size: 14px;
  text-align: left;
  line-height: 22px;
  margin-bottom: 10px;
}
</style>
