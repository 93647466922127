<!-- 步骤条 -->
<template>
  <div class="steps-cl">
    <el-steps :active="active" finish-status="success" align-center>
      <el-step v-for="(item, i) of stepList" :key="i" :title="item.stepTitle"></el-step>
    </el-steps>
    <slot></slot>
    <div class="steps-cl--footer" v-if="!hideBtn">
      <ButtonHoc class="steps-cl--footer-btn" @click="toBack">上一步</ButtonHoc>
      <ButtonHoc v-if="showNextBtn" class="steps-cl--footer-btn" type="primary" @click="toNext"
        >下一步</ButtonHoc
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    active: {
      type: Number,
      default: 0,
    },
    stepList: {
      // stepList: [{stepTitle: '步骤1'}],
      type: Array,
      default: [],
    },
    showNextBtn: {
      type: Boolean,
      default: true,
    },
    hideBtn: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  computed: {},

  created() {},

  mounted() {},

  methods: {
    toBack() {
      this.$emit('toBack');
    },
    toNext() {
      this.$emit('toNext');
    },
  },
};
</script>
<style lang='scss' scoped>
.steps-cl {
  margin-top: 50px;
  margin-bottom: 50px;
  /deep/ .el-step__head.is-process {
    color: #AB0033;;
    border-color: #AB0033;;
  }
  /deep/ .el-step__title.is-process {
    color: #AB0033;;
  }
  /deep/ .el-step__head {
    margin-bottom: 11px;
  }
  /deep/ .el-step__head.is-success {
    color: #fff;
    border-color: #AB0033;;
    .el-step__icon.is-text {
      background-color: #AB0033;;
    }
    .el-step__icon-inner.is-status {
      font-size: 41px;
    }
    .el-step__line {
      background-color: #AB0033;;
    }
  }
  /deep/ .el-step__title.is-success {
    color: #AB0033;;
  }
  /deep/ .el-step__icon {
    width: 67px;
    height: 67px;
    font-size: 26px;
    border-style: dotted;
  }
  /deep/ .el-step.is-horizontal .el-step__line {
    top: 33px;
  }
  &--content-box {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &--footer {
    text-align: center;
    &-btn {
      width: 249px;
      height: 40px;
      font-size: 18px;
      border-radius: 5px;
      /deep/ &.el-button + &.el-button {
        margin-left: 55px;
      }
    }
  }
}
</style>