<!-- Dialog 封装 -->
<template>
  <el-dialog
    :class="[topStyle]"
    :title="title"
    :width="vWidth ? vWidth : width"
    :visible.sync="dialogVisible"
    v-bind="$attrs"
    v-on="$listeners"
    :before-close="close"
  >
    <div ref="currentView"></div>
    <span slot="footer" class="dialog-footer">
      <ButtonHoc v-if="isBtn" class="btn-cls" @click="close">取 消</ButtonHoc>
      <ButtonHoc class="btn-cls" :class="isBtn ? '' : 'submit-cls'" type="primary" @click="onSubmit"
        >确 定</ButtonHoc
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      ...this.initialData(),
      dialogVisible: false,
      model: 'show-top', // 隐藏顶部'hide-top'  显示顶部'show-top'
      provideData: {}, // 给子组件的初始参数
      vm: {}, // 渲染的vnode
    };
  },
  name: 'Y-Dialog',
  components: {},
  computed: {
    topStyle() {
      return this.model === 'hide-top' ? 'hide-top' : '';
    },
    isBtn() {
      return this.vCloseBtn === '' ? this.closeBtn : this.vCloseBtn;
    },
  },
  props: {
    width: {
      type: String,
      default: '30%',
    },
    closeBtn: {
      type: Boolean,
      default: false,
    },
  },
  created() {},

  mounted() {},

  methods: {
    //初始数据
    initialData: () => {
      return {
        title: '',
        vWidth: '',
        vCloseBtn: '',
      };
    },
    // 外部调用
    open(initData) {
      Object.assign(this, { ...this.initialData() });
      const { title, width, model, component, data, closeBtn = '' } = initData;
      this.title = title;
      this.vWidth = width;
      this.model = model;
      if (closeBtn !== '') this.vCloseBtn = closeBtn;
      this.dialogVisible = true;
      this.$nextTick(() => {
        const Profile = window.$vue.extend(component);
        const odiv = document.createElement('div');
        const el = this.$refs.currentView.appendChild(odiv);
        this.vm = new Profile({
          el: el, // 挂载模板
          data: () => {
            return {
              YDialogInitData: { ...data }, // 传入组件内部的初始参数
            };
          },
          destroyed() {
            // console.log(`${component.name}组件销毁了`);
          },
          methods: {
            // 子组件暴露一个方法传递参数， 如果子组件要使用必须再定义覆盖一遍
            // getChildData() {
            //   return {};
            // },
          },
        });
      });
    },
    // 外部调用关闭
    close() {
      if (!this.vm.$el.parentNode) return;
      this.dialogVisible = false;
      this.vm.$el.parentNode.removeChild(this.vm.$el); // 删除渲染DOM
      this.vm.$destroy(); // 销毁组件
    },
    // 确定
    onSubmit() {
      if (this.vm.getChildData) {
        const data = this.vm.getChildData(); // 输出子组件数据
        this.$emit('ok', data);
        if (typeof data === 'boolean' && data === false) return;
        this.close();
      } else {
        this.close();
      }
    },
  },
};
</script>
<style lang='scss' scoped>
/deep/ {
  .el-dialog__footer {
    text-align: center;
  }
}
.hide-top {
  /deep/ {
    .el-dialog__header {
      display: none;
    }
  }
}

.btn-cls {
  height: 40px;
  border-radius: 5px;
  font-size: 18px;
}
.submit-cls {
  width: 249px;
  background: #AB0033;;
}
</style>