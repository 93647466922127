<template>
  <div class="wrap">
    <div class="tips">
      <p>
        授权品牌说明：授权的品牌可以在对应的渠道和店铺里面进行使用，可使用的时间必须在有效期以内；
      </p>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      border
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
    >
      <el-table-column align="center" label="授权品牌" prop="brandName"></el-table-column>
      <el-table-column align="center" label="授权店铺" prop="shopName"></el-table-column>
      <!-- <el-table-column align="center" label="授权渠道" prop="statusName"></el-table-column> -->
      <el-table-column align="center" label="有效截止日期" prop="typeName">
        <template slot-scope="scope"
          >{{ scope.row.startDate | parseTime('{y}-{m}-{d}') }}至{{
            scope.row.endDate | parseTime('{y}-{m}-{d}')
          }}</template
        >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { listBrandAuthorizations } from '@/api/shop';
export default {
  name: 'BasicInfo',
  data() {
    return {
      tableContract: [],
      headerCellStyle: { backgroundColor: '#F3F3F3', fontWeight: '400', fontSize: '12px' },
      headerRowStyle: { color: '#333333' },
      list: []
    };
  },
  mounted() {
    this.listBrandAuthorizations();
  },
  methods: {
    listBrandAuthorizations() {
      listBrandAuthorizations(this.contractQuery).then((res) => {
        this.list = res.data || [];
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import './brand-info';
</style>