import {
  isPhoneNumber,
  isSimplePhoneNum,
  validateMoney2,
  isPercent,
  isIDCard,
  isNumber,
  isPhoneNumberAndVirtual,
} from '@/utils/validate';
export function validateName(rule, str, callback) {
  const value = str.trim();
  if (value === '') {
    callback('请填写姓名');
    return;
  }
  if (value.length > 10) {
    callback('请填写1-10个字');
    return;
  }
  const number = parseFloat(value, 10);
  if (!isNaN(value) && number.toString() === value) {
    // 纯数字
    callback('请填写非纯数字');
    return;
  }
  callback();
}

export function validateIdCard(rule, str, callback) {
  const value = str.trim();
  if (!isIDCard(value)) {
    callback('请填写正确的身份证格式');
    return;
  }
  callback();
}

export function validatePhone(rule, str, callback) {
  const value = str.trim();
  if (!isPhoneNumber(value)) {
    callback('请填写正确的手机号码');
    return;
  }
  callback();
  return;
}

//  验证手机号码支持虚拟号码
export function validatePhoneVirtual(rule, str, callback) {
  const value = str.trim();
  if (!isPhoneNumberAndVirtual(value)) {
    callback('请填写正确的手机号码');
    return;
  }
  callback();
  return;
}

export function validatePhoneSimple(rule, str, callback) {
  const value = str.trim();
  if (!isSimplePhoneNum(value)) {
    callback('请填写正确的手机号码');
    return;
  }
  callback();
  return;
}

export function validateMaxNum(rule, str, callback) {
  if (!isNumber(str)) {
    callback('请输入数字');
    return;
  }
  if (str > 100000000) {
    callback('数据大于1个亿，请重新输入');
    return;
  }
  callback();
  return;
}

export function validateLength(rule, str, callback) {
  const value = str.trim();
  if (value.length > 15) {
    callback('标题不能超过15个文字');
    return;
  }
  callback();
}

export function validateMoney(rule, str, callback) {
  if (str) {
    if (str <= 0 || str > 1000000) {
      callback('充值金额在0-1000000之间');
      return;
    }
    const reg = /^[0-9]\d*(\.\d{1,2})?$/;
    if (!reg.test(str)) {
      callback('数据有误，请重新输入');
      return;
    }
  }
  callback();
}

export function validateMoneyNum(num) {
  return (rule, str, callback) => {
    if (str) {
      if (str <= 0 || str > num) {
        callback(`充值金额在0-${num}之间`);
        return;
      }
      if (!validateMoney2(str)) {
        callback('数据有误，请重新输入');
        return;
      }
    }
    callback();
  };
}

export function validateInteger(rule, str, callback) {
  if (str) {
    if (typeof str === 'number') {
      str = str.toString();
    }
    const value = str.trim();
    const reg = /^([1-9]\d*|[0]{1,1})$/;
    if (!reg.test(value)) {
      callback('数据有误，请重新输入');
      return;
    } else if (value > 99999999) {
      callback('请输入小于1亿的数字');
      return;
    }
  }
  callback();
}

export function validatePercent(rule, str, callback) {
  if (str) {
    if (!isNumber(str) || str > 100) {
      callback('请输入正确的百分数');
      return;
    }
    str = str.toString();
    const value = str.trim();
    if (!isPercent(value)) {
      callback('请输入正确的百分数并保留两位小数');
      return;
    }
  }
  callback();
}

export function validateMaxMoney(rule, str, callback) {
  if (!isNumber(str)) {
    callback('输入的数据过大,请重新输入');
    return;
  }
  if (str > 100000000) {
    callback('数据大于1个亿，请重新输入');
    return;
  }
  callback();
  return;
}
// 标签添加判断
export function validateLabel(rule, str, callback) {
  if (str.match(/^\s*$/)) {
    callback('请输入内容再添加');
    return;
  }
  callback();
}
