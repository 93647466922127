<!-- 品牌授权 -->
<template>
  <div>
    <component @toApply="toApply" :is="componentName"></component>
  </div>
</template>

<script>
import listComponent from './components/list.vue';
import myform from './components/form';
export default {
  data() {
    return {
      componentName: 'listComponent',
    };
  },
  name: 'BrandAuthorization',
  components: { listComponent, myform },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    toApply(name) {
      this.componentName = name;
    },
  },
};
</script>
<style lang='scss' scoped>
</style>