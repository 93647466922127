import { withExtTenantIdRequest } from '@/utils/request';
// 创建充值记录
export function create (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorRechargeLog/create',
    method: 'post',
    data
  });
}
//获取支付服务bizCode
export function getPayBizCode (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/order/getPayBizCode',
    method: 'post',
    data
  });
}
// 调用支付服务-微信扫码支付
export function wxNative (data) {
  data.payClientType = 'GZHSHOP';
  return withExtTenantIdRequest({
    url: '/pay/api/weChatPay/wxNative',
    method: 'post',
    data,
    headers: {
      'X-Biz-Code': data.payBizCode
    }
  });
}
// 调用支付服务-支付宝扫码支付
export function qrCode (data) {
  data.payClientType = 'GZHSHOP';
  return withExtTenantIdRequest({
    url: '/pay/api/alipay/qrCode',
    method: 'post',
    data,
    headers: {
      'X-Biz-Code': data.payBizCode
    }
  });
}
// 调用支付服务-新生支付-支付宝扫码支付
export function qrCodeNew (data) {
  return withExtTenantIdRequest({
    url: '/pay/api/newPay/qrcode',
    method: 'post',
    data,
    headers: {
      'X-Biz-Code': 'GZHSHOP'
    }
  });
}
// 是否展示对公支付
export function getPayChannel (orderAmount, ownCompanyId = '') {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/allInPay/getPayChannel?orderAmount=${orderAmount}&ownCompanyId=${ownCompanyId}`,
    method: 'get'
  });
}
// 调用支付服务-对公支付
export function allInPay (data) {
  data.payClientType = 'GZHSHOP';
  return withExtTenantIdRequest({
    url: '/pay/api/allInPay/pay',
    method: 'post',
    // 长沙水羊
    headers: {
      'X-Biz-Code': 'INTERNATION_BRAND'
    },
    data
  });
}
// 验证账户是否设置支付密码
export function validPayPassword () {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/syzgAccountPay/validPayPassword`,
    method: 'post'
  });
}
// 使用余额/授信 支付
export function syzgAccountPayPaid (data, encrypted, timestamp) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/syzgAccountPay/paid`,
    method: 'post',
    data,
    headers: {
      'X-Tsign-Open-TIMESTAMP': timestamp, // 时间戳
      'X-Tsign-Open-SIGN': encrypted // 加密串
    }
  });
}
// 获取收银台账户信息-余额/授信
export function getAccountInfo () {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/syzgAccountPay/getAccountInfo`,
    method: 'get'
  });
}
export function getCustomerAccount (data) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/syzgAccountPay/getCustomerAccount`,
    method: 'post',
    data
  });
}
// 线下转账申请-新增
export function distributorChargeRecordCreate (data) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/distributorChargeRecord/create`,
    method: 'post',
    data
  });
}

// 线下转账申请-获取
export function distributorChargeRecordGet(params) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorChargeRecord/get',
    method: 'get',
    params
  });
}

// 线下转账申请-分页查询
export function distributorChargeRecordListMyPage (data) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/distributorChargeRecord/listMyPage`,
    method: 'post',
    data
  });
}

// 获取银行卡列表
export function distributorBankCardListAll (data = {}) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/distributorBankCard/listAll`,
    method: 'post',
    data
  });
}

// 创建银行卡
export function distributorBankCardCreate (data = {}) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/distributorBankCard/create`,
    method: 'post',
    data
  });
}

// 编辑银行卡
export function distributorBankCardUpdate (data = {}) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/distributorBankCard/update`,
    method: 'post',
    data
  });
}

export function distributorBankCardDelete (params = {}) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/distributorBankCard/delete`,
    method: 'post',
    params
  });
}

// 获取银行卡
export function distributorBankCardGet (params) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/distributorBankCard/get`,
    method: 'get',
    params
  });
}

// 获取公司账户
export function distributorChargeRecordGetCorporateAccounts (data = []) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/distributorChargeRecord/getCorporateAccounts`,
    method: 'post',
    data
  });
}

// 余额提现申请
export function distributorWithdrawRecordCreate (data, encrypted, timestamp) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/distributorWithdrawRecord/create`,
    method: 'post',
    headers: {
      'X-Tsign-Open-TIMESTAMP': timestamp, // 时间戳
      'X-Tsign-Open-SIGN': encrypted, // 加密串
    },
    data
  });
}

// 获取账户充值主体信息
export function listAccountChargeContractInfo (data = {}) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/distributorContractInfo/listAccountChargeContractInfo`,
    method: 'post',
    data
  });
}

// 获取账户主体公司对应的账户（余额、保证金）
export function listCustomerAccountCompany (data = {}) {
  return withExtTenantIdRequest({
    url: `soyoungzg/api/customerAccount/listCustomerAccountCompany`,
    method: 'post',
    data
  });
}