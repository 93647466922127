import { withExtTenantIdRequest } from '@/utils/request';

export function getTodayStatistics () {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/orderStatistics/getTodayStatistics',
    method: 'get'
  });
}

// 我的品牌授权列表
export function distributorLicenseListMine (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorLicense/listMine',
    method: 'post',
    data
  });
}

// 字典
export function dictListByType (type) {
  return withExtTenantIdRequest({
    url: `/common/api/dict/listByType?type=${type}`,
    method: 'get'
  });
}

// 品牌授权-其他渠道名称
export function distributorContractListOtherChannel (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContract/listOtherChannel',
    method: 'post',
    data
  });
}
// 获取分销商经营渠道(移动端)
export function distributorDetDistributorChannelForApp (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributor/getDistributorChannelForApp',
    method: 'post',
    data
  });
}

// 品牌授权-品牌列表(有店)
export function brandLicenseListMyPage (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/brandLicense/listMyPage',
    method: 'post',
    data
  });
}

// 品牌授权-品牌列表(无店)
export function storelessLicenseListMyLicense (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/storelessLicense/listMyLicense',
    method: 'post',
    data
  });
}


// 品牌授权-授权达成详情
export function brandLicenseListMyBrandLicense (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/brandLicense/listMyBrandLicense',
    method: 'post',
    data
  });
}

// 品牌授权-无店模式授权达成详情
export function storelessLicenseGetMyLicenseDetail (brandId, channel, orderId, tradeType) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/storelessLicense/getMyLicenseDetail?id=${brandId}&channel=${channel}&orderId=${orderId}&tradeType=${tradeType}`,
    method: 'get',
  });
}


// 品牌授权-无店模式满足授权条件订单列表
export function storelessLicenseListMyLicenseOrders (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/storelessLicense/listMyLicenseOrders',
    method: 'post',
    data
  });
}


// 品牌授权-填写授权资料-可选的授权企业/可选人名
export function distributorContractListMineEffect (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContract/listMineEffect',
    method: 'post',
    data
  });
}
export function distributorContractInfoListByAvailableByApp (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/listByAvailableByApp',
    method: 'post',
    data
  });
}
// 查看特殊授权的品牌-比如大水滴、大陆大水滴
export function brandLicenseListSpecialBrand () {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/brandLicense/listSpecialBrand',
    method: 'get',
  });
}


// 品牌授权-填写授权资料 (有店)
export function licenseApplyRecordSubmit (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/licenseApplyRecord/submit',
    method: 'post',
    data
  });
}

// 品牌授权-填写授权资料 (无店)
export function storelessLicenceApplyBatchSubmit (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/storelessLicenceApplyBatch/submit',
    method: 'post',
    data
  });
}

// 品牌授权-授权申请记录
export function licenseApplyRecordListMine (data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/licenseApplyRecord/listMine',
    method: 'post',
    data
  });
}


// 品牌授权-授权申请记录-查看驳回原因
export function licenseApplyRecordGetRejectReason(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/licenseApplyRecord/getRejectReason?id=${id}`,
    method: 'get',

  });
}

// 品牌授权-授权规则
export function brandLicenseConfigGet() {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/brandLicenseConfig/get`,
    method: 'get',

  });
}

// 品牌授权-授权书管理-续签弹出框
export function distributorLicensePreResign(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributorLicense/preResign?id=${id}`,
    method: 'post',
  });
}

// 品牌授权-授权书管理-续签
export function distributorLicenseResign(data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorLicense/resign',
    method: 'post',
    data
  });
}
