var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modify"},[_c('el-form',{ref:"ruleForm",attrs:{"inline-message":true,"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"选择平台","prop":"platform"}},[_c('el-select',{attrs:{"filterable":"","placeholder":"请选择平台"},on:{"change":_vm.changeInput},model:{value:(_vm.ruleForm.platform),callback:function ($$v) {_vm.$set(_vm.ruleForm, "platform", $$v)},expression:"ruleForm.platform"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":_vm.ruleForm.platform === 'TAOBAO' ||  _vm.ruleForm.platform === 'TMALL' ? '旺旺ID' : '平台店铺ID',"prop":"platformShopId","rules":[
          {
            required: _vm.ruleForm.platform === 'TAOBAO' ||  _vm.ruleForm.platform === 'TMALL' ? true : false,
            message: '淘系必填信息',
            trigger: 'blur',
          },
        ]}},[_c('el-input',{attrs:{"placeholder":_vm.ruleForm.platform === 'TAOBAO' ||  _vm.ruleForm.platform === 'TMALL'  ? '请填写旺旺ID' : '请填写平台店铺ID'},model:{value:(_vm.ruleForm.platformShopId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "platformShopId", $$v)},expression:"ruleForm.platformShopId"}})],1),_c('el-form-item',{attrs:{"label":"平台店铺名称","prop":"platformShopName"}},[_c('el-input',{attrs:{"placeholder":"请填写平台店铺名称"},model:{value:(_vm.ruleForm.platformShopName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "platformShopName", $$v)},expression:"ruleForm.platformShopName"}})],1),_c('el-form-item',{attrs:{"label":"店铺网址","prop":"shopWebsite"}},[_c('el-input',{attrs:{"placeholder":"请填写店铺网址"},model:{value:(_vm.ruleForm.shopWebsite),callback:function ($$v) {_vm.$set(_vm.ruleForm, "shopWebsite", $$v)},expression:"ruleForm.shopWebsite"}})],1),_c('p',{staticClass:"tips"},[_vm._v("注:请填写正确的店铺链接，防止被品牌方打假")]),_c('el-form-item',[_c('el-button',{staticClass:"modify__btn--save",attrs:{"loading":_vm.saveLoading,"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }