<!-- tip展示组件 -->
<template>
  <div class="t-tips">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
.t-tips {
  width: 100%;
  padding: 10px;
  background-color: #fffaf1;
  color: #e98e5e;
  font-size: 14px;
  text-align: left;
  line-height: 22px;
  margin-bottom: 10px;
}
</style>