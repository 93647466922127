<!-- 选择订单 -->
<template>
  <div>
    <el-table :data="list" style="width: 100%">
      <el-table-column prop="orderNo" label="订单号"> </el-table-column>
      <el-table-column prop="createDate" label="下单日期"
        ><template slot-scope="scope">
          <div>
            {{ scope.row.createDate | parseTime }}
          </div>
        </template></el-table-column
      >
      <el-table-column label="可授权品牌">
        <template slot-scope="scope">
          <div>
            {{ fs(scope.row.brandVOList) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="list-btn-box">
            <el-button class="yx" v-if="scope.row.checked" disabled>已选</el-button>
            <ButtonHoc type="primary" v-else @click="choose(scope.row)">选择</ButtonHoc>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { storelessLicenseListMyLicenseOrders } from '@/api/brandauthorization';
export default {
  data() {
    return {
      list: [],
    };
  },

  components: {},

  computed: {},

  created() {
    this.getList();
  },

  mounted() {},

  methods: {
    fs(list = []) {
      return list.map((item) => item.name).join('、');
    },
    getList() {
      const { channel, id, firstPurchaseOrderId } = this.YDialogInitData;
      const parameter = {
        id,
        channel,
      };
      storelessLicenseListMyLicenseOrders(parameter).then((res) => {
        this.list = this.setChecked(res.data, firstPurchaseOrderId);
      });
    },
    setChecked(list, orderId) {
      return list.map((item) => ({
        ...item,
        checked: item.orderId === orderId,
      }));
    },
    choose(row) {
      this.list = this.setChecked(this.list, row.orderId);
    },
    // 给父弹出框调用 用于传参
    getChildData() {
      const { id } = this.YDialogInitData;
      return { ...this.list.find((item) => item.checked), id };
    },
  },
};
</script>
<style lang='scss' scoped>
.list-btn-box {
  /deep/ .el-button {
    width: 88px;
    font-size: 18px;
    font-weight: bold;
  }
  .yx {
    background: #f7ced5;
    border-radius: 5px;
    color: #AB0033;;
  }
}
</style>