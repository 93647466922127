export function isvalidUsername(str) {
  const valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/* 合法uri*/
export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/* 大小写字母*/
export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/* 整数金钱校验，位数不大于999999999*/
export function validateMoney(value) {
  const reg = /^([1-9][0-9]*)$/;
  if (value === null || value === '') {
    return '请输入授信额度';
  } else if (reg.test(value)) {
    if (value > 999999999) {
      return '授信额度最大不能大于999999999';
    } else {
      return true;
    }
  } else {
    return '数据格式不正确，请输入大于1的整数';
  }
}
/* 整数校验——库存*/
export function isInteger(value) {
  const reg = /^([1-9]\d*|[0]{1,1})$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}
/* 保留两位小数的金钱校验 */
export function validateMoney2(value) {
  const reg = /^([1-9]{1}[0-9]{0,3}(\,[0-9]{3,4})*(\.[0-9]{0,2})?|[1-9]{1}\d*(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

/* 保留两位小数的数字校验 */
export function validateFloatNum(value) {
  const reg = /^([1-9]{1}[0-9]{0,3}(\,[0-9]{3,4})*(\.[0-9]{0,2})?|[1-9]{1}\d*(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

/* 保留两位小数的百分数校验 */
export function isPercent(value) {
  const reg = /^(\d|[1-9]\d|100)(\.\d{1,2})?$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

/* 校验倍数 大于等于1 小数位保留1位小数*/
export function validateMultiple(value) {
  const reg = /^([1-9][0-9]*)+(.[0-9]{1})?$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

/* 整数的1-100百分数校验 */
export function isIntPercent(value) {
  const reg = /^([1-9][0-9]{0,1}|100)$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}
/**
 * 判断字符串是否是纯数字
 * @param str
 * @returns {boolean}
 */
export function isNumber(str) {
  if (typeof str === 'number') {
    return true;
  }
  if (!str) {
    return false;
  }
  return parseFloat(str, 10).toString() === str;
}
/**
 * 判断字符串是否全是数字
 * @param str
 * @returns {boolean}
 */
export function isAllNumber(str) {
  return /^\d{0,}$/.test(str);
}
/**
 * 判断是否是身份证
 * 参考链接http://www.css88.com/archives/7982
 * @param idcard
 * @returns {boolean}
 */

/* 校验身份证号  */
export function isIDCard(id) {
  // 转换大小写
  id = id.toUpperCase();
  const arrVerifyCode = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
  const Wi = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  const Checker = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
  if (id.length != 15 && id.length != 18) {
    return false;
  }
  let Ai = id.length == 18 ? id.substring(0, 17) : id.slice(0, 6) + '19' + id.slice(6, 16);
  if (!/^\d+$/.test(Ai)) {
    return false;
  }
  let yyyy = Ai.slice(6, 10),
    mm = Ai.slice(10, 12) - 1,
    dd = Ai.slice(12, 14);
  let d = new Date(yyyy, mm, dd),
    now = new Date();
  let year = d.getFullYear(),
    mon = d.getMonth(),
    day = d.getDate();
  if (year != yyyy || mon != mm || day != dd || d > now || year < 1800) {
    return false;
  }
  for (var i = 0, ret = 0; i < 17; i++) {
    ret += Ai.charAt(i) * Wi[i];
  }
  Ai += arrVerifyCode[(ret %= 11)];
  if (id.length == 18 && id != Ai) {
    return false;
  }
  return true;
}

export function isPhoneNumber(str) {
  return /^1[3456789]\d{9}$/.test(str);
}
// 验证手机号码支持虚拟号码
export function isPhoneNumberAndVirtual(str) {
  return /^1[3-9]\d{9}$|^\d{11}-\d{4}$/.test(str);
}

export function isSimplePhoneNum(str) {
  return /^1\d{10}$/.test(str);
}
/* 折扣校验 大于0小于10且保留一位小数*/
export function validatediscount1(value) {
  const reg = /^([0-9](\.\d)?|9)$/;
  if (reg.test(value)) {
    return true;
  } else {
    return false;
  }
}

// 校验数字的范围
export function validateRangeNumber(min, max) {
  return (_, value, callback) => {
    value = parseFloat(value);
    if ((min != null && value < min) || (max != null && value > max)) {
      callback(new Error('数字超出范围'));
    } else {
      callback();
    }
  };
}

// 校验数字
export function validateNumber(_, value, callback) {
  const str = `${value}`;
  if (!str || /^-?\d+\.?\d*$/.test(str)) {
    callback();
  } else {
    callback('请填写数字');
  }
}

// el-form 校验数字为整数
export function validateInteger(_, value, callback) {
  if (!value || /^\d+$/.test(`${value}`)) {
    callback();
  } else {
    callback(new Error('请填写整数'));
  }
}

// 校验时间范围
export const validateRangeDate = (start, end) => (_, value, callback) => {
  const [startDate, endDate] = value;
  if (value.length && ((start && startDate < start) || (end && endDate < end))) {
    callback(new Error('超出时间范围'));
  } else {
    callback();
  }
};

// 校验手机号   (宽松), 只要是13,14,15,16,17,18,19开头即可
export function validatePhoneLoose(_, value, callback) {
  console.log(value);
  const str = `${value}`;
  const regular = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
  if (regular.test(str)) {
    callback();
  } else {
    callback(new Error('请填写正确手机号'));
  }
}

// 校验手机号 + 固定电话
export function validateLandlineLoose(_, value, callback) {
  const mobile = `${value}`;
  const tel = /([0-9]{2,3,4}-)?[0-9]{7,8}$/;
  const phone = /^1[3-9]\d{9}$/;

  if (mobile.length == 11) {
    if (mobile.indexOf('-') !== -1 && tel.test(mobile)) {
      callback();
    } else if (phone.test(mobile)) {
      callback();
    }
  }

  callback(new Error('请填写正确手机号或固定电话'));
}
// 校验邮箱
export function validateEmail(_, value, callback) {
  const str = `${value}`;
  const regular = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regular.test(str)) {
    callback();
  } else {
    callback(new Error('请填写正确邮箱'));
  }
}

// 判断是否是JSON
export function isJSON(str) {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log('error：' + str + '!!!' + e);
      return false;
    }
  }
  console.log('It is not a string!');
}
