<!-- 选择授权渠道 -->
<template>
  <div class="steps-cl--content-box">
    <div class="chacnnel-change">
      <el-form ref="formName" :rules="rules" label-width="160px" :model="formLabelAlign">
        <el-form-item label="选择授权渠道：" prop="channelValue">
          <el-select
            filterable
            v-model="formLabelAlign.channelValue"
            clearable
            placeholder="请选择"
            @change="channeChange"
          >
            <el-option
              v-for="item in channelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formLabelAlign.channelValue === 'OTHER'"
          label="其他渠道名称："
          prop="channelValue"
        >
          <el-select
            filterable
            v-model="formLabelAlign.otherValue"
            clearable
            placeholder="请选择"
            @change="otherChange"
          >
            <el-option
              v-for="item in otherList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item filterable label="选择店铺类型：" prop="shopTypeValue">
          <el-select v-model="formLabelAlign.shopTypeValue" clearable placeholder="请选择">
            <el-option
              v-for="item in shopTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  dictListByType,
  distributorDetDistributorChannelForApp
} from '@/api/brandauthorization';
import { getUserInfo } from '@/common/localStorage/user';
export default {
  data() {
    return {
      formLabelAlign: {
        channelValue: '', // 渠道
        shopTypeValue: '', // 店铺
        otherValue: '', // 其他渠道
      },
      channelName: '', // 渠道名称
      otherName: null, // 其他渠道名称
      shopTypeList: [], // 店铺list
      channelList: [], // 渠道list
      rules: {
        channelValue: [{ required: true, message: '请选择授权渠道', trigger: 'change' }],
        shopTypeValue: [{ required: true, message: '请选择授权渠道', trigger: 'change' }],
      },
      otherList: [],
    };
  },
  inject: ['getInitData'], // 公共数据
  props: {},
  components: {},

  computed: {},

  created() {
    this.getSelect();
  },

  mounted() {},

  methods: {
    // 获取下拉框数据
    getSelect() {
      const q1 = dictListByType('soyoungzg_shop_type').then((res) => {
        // 店铺类型
        this.shopTypeList = res.data || [];
      });
      const memberId = getUserInfo().memberId;
      const q2 = distributorDetDistributorChannelForApp({ id: memberId }).then((res) => {
        // 授权渠道
        const { channelInfoVOList = [], otherChannelInfo = [] } = res.data;
        this.channelList = channelInfoVOList.map((item) => {
          return {
            label: item.channelName,
            value: item.channel,
          };
        });
        this.otherList = otherChannelInfo.map(item => {
          return {
            label: item,
            value: item,
          };
        });
      });
      Promise.all([q1, q2]).then(() => {
        // 获取初始值
        const ininData = this.getInitData();
        if (Object.keys(ininData).length > 0) {
          Object.keys(this.formLabelAlign).forEach((key) => {
            if (ininData[key]) this.formLabelAlign[key] = ininData[key];
          });
        }
      });
    },
    // 外部调用
    next(callback) {
      this.$refs['formName'].validate((valid) => {
        if (valid) {
          const { channelName = '', otherName = '' } = this.getInitData();
          callback({
            ...this.formLabelAlign,
            channelName: this.channelName ? this.channelName : channelName,
            otherName: this.otherName ? this.otherName : otherName,
          });
        } else {
          return false;
        }
      });
    },
    channeChange(value) {
      const channel = this.channelList.find((item) => item.value === value);
      this.channelName = channel.label;
    },
    // 其他渠道
    otherChange(value) {
      const other = this.otherList.find((item) => item.value === value);
      this.otherName = other.label;
    },
  },
};
</script>
<style lang='scss' scoped>
.chacnnel-change {
  /deep/ .el-form-item__label {
    font-size: 18px;
    color: #262626;
  }
  /deep/ .el-select {
    width: 390px;
    font-size: 18px;
  }
}
.steps-cl {
  &--content-box {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
