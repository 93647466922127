<!-- 有店 -->
<template>
  <div class="my-form-box">
    <el-form
      ref="formName"
      :rules="rules"
      label-width="160px"
      :model="formLabelAlign"
    >
      <el-form-item label="授权品牌：">
        <div class="logobox">
          <img :src="current.logoUrl" :alt="current.brandName" />
          <div>
            {{ current.brandName
            }}<span v-if="current.izShowTrade === '1'" style="font-size: 12px"
              >（{{ current.tradeTypeName }}）</span
            >
          </div>
        </div>
      </el-form-item>
      <el-form-item label="授权渠道：" prop="channelValue">
        <div>
          {{ channelName }}<span v-if="otherName">({{ otherName }})</span>
        </div>
      </el-form-item>
      <el-form-item label="主体类型：" prop="mainBodyType">
        <el-select
          filterable
          v-model="formLabelAlign.mainBodyType"
          clearable
          placeholder="请选择"
          @change="changeMainBodyType"
        >
          <el-option
            v-for="item in mainBodyTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="mainBodyId">
        <template v-slot:label>
          <label
            ><i class="el-icon-question" @click="onDoubt"></i
            >{{
              formLabelAlign.mainBodyType === "ENTERPRISE"
                ? "主体名称："
                : "个人姓名："
            }}</label
          >
        </template>
        <el-select
          filterable
          v-model="mainBodyId"
          clearable
          placeholder="请选择"
          @change="changeLicenseCompany"
        >
          <el-option
            v-for="(item, index) in licenseCompanyList"
            :key="item.value + index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="tip">{{ licenseCompanyTip }}</div>
      </el-form-item>
      <el-form-item label="授权店铺名称：" prop="platformShopName">
        <el-input v-model="formLabelAlign.platformShopName"></el-input>
      </el-form-item>
      <el-form-item label="授权店铺ID：" prop="platformShopId">
        <el-input v-model="formLabelAlign.platformShopId"></el-input>
      </el-form-item>
      <el-form-item label="授权店铺网址：" prop="shopWebsite">
        <el-input
          type="textarea"
          v-model="formLabelAlign.shopWebsite"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="承诺函："
        prop="commitmentLetter"
        v-if="hasCommitmentLetter"
      >
        <upload
          :accept="accept"
          is-private
          is-valid
          :limit="1"
          :max-capacity="10"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
        >
          <el-button size="small" type="primary">上传文件</el-button>
          <el-button type="text" @click="downloadPromiseTemplate"
            >点击下载承诺函模板</el-button
          >
          <p class="tip">只能上传{{ acceptFormat }}文件，且不超过10MB</p>
        </upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Upload from '@/components/Upload';
import {
  brandLicenseListSpecialBrand,
  distributorContractInfoListByAvailableByApp,
  licenseApplyRecordSubmit
} from '@/api/brandauthorization';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { parseTime } from '@/utils';
export default {
  data() {
    return {
      accept: '.doc,.docx,.jpeg,.jpg,.png,.pdf',
      current: {}, // 选中的信息
      shopTypeList: [],
      mainBodyId: '',
      formLabelAlign: {
        mainBodyId: '', // 主体ID  ,
        platformShopName: '', // 授权店铺名称
        platformShopId: '', // 店铺ID
        shopWebsite: '', // 店铺链接
        mainBodyType: 'ENTERPRISE', //主体类型
        commitmentLetter: '' // 承诺函
      },
      mainBodyTypeList: [
        { label: '企业', value: 'ENTERPRISE' },
        { label: '个人', value: 'PERSONAL' }
      ],
      taxNo: '', // 授权企业税号，如果是个人则传身份证号码
      channelName: '', // 渠道名称
      channelValue: '',
      otherName: '', // 其他渠道名称
      licenseCompany: '', // 授权企业名称,如果是个人则传姓名 ,
      shopTypeName: '',
      companyList: [], // 主体列表
      personalList: [], // 个人列表
      rules: {
        mainBodyId: [
          { required: true, message: '必填信息', trigger: 'change' }
        ],
        platformShopName: [
          { required: true, message: '必填信息', trigger: 'blur' }
        ],
        platformShopId: [
          { required: true, message: '必填信息', trigger: 'blur' }
        ],
        shopWebsite: [{ required: true, message: '必填信息', trigger: 'blur' }],
        mainBodyType: [
          { required: true, message: '必填信息', trigger: 'change' }
        ],
        commitmentLetter: [
          { required: true, message: '必填信息', trigger: 'change' }
        ]
      },
      specialBrandList: [] // 特殊授权品牌列表
    };
  },
  inject: ['getInitData'],
  components: {
    Upload
  },

  computed: {
    ...mapGetters(['userInfo']),
    acceptFormat() {
      return this.accept
        .replace(/\./g, '')
        .split(',')
        .join('/');
    },
    // 当前选择的主体信息
    currentMainBodyInfo() {
      const { mainBodyId } = this;
      const { mainBodyType } = this.formLabelAlign;
      const mainBodyList =
        mainBodyType === 'ENTERPRISE' ? this.companyList : this.personalList;
      const list = mainBodyList.filter(item => item.value === mainBodyId);
      if (list.length) {
        return list[0];
      }
      return null;
    },
    // 是否有承诺函
    hasCommitmentLetter() {
      return !!(
        this.specialBrandList.includes(this.current.brandId) ||
        (this.currentMainBodyInfo &&
          !this.currentMainBodyInfo.distributorContractId)
      );
    },
    licenseCompanyTip() {
      const mainBodyType = this.formLabelAlign.mainBodyType;
      if (mainBodyType === 'ENTERPRISE' && this.companyList.length === 0) {
        return '暂无企业可选';
      }
      if (mainBodyType === 'PERSONAL' && this.personalList.length === 0) {
        return '暂无个人名称可选';
      }
      return '';
    },
    licenseCompanyList() {
      const mainBodyType = this.formLabelAlign.mainBodyType;
      if (mainBodyType === 'ENTERPRISE') return this.companyList;
      if (mainBodyType === 'PERSONAL') return this.personalList;
      return [];
    }
  },

  created() {
    const {
      current,
      channelName,
      otherName,
      channelValue
    } = this.getInitData();
    this.current = current;
    this.channelName = channelName;
    this.channelValue = channelValue;
    if (otherName) this.otherName = otherName;
    this.getDist('companyList', 'ENTERPRISE');
    this.getDist('personalList', 'PERSONAL');
    this.getSpecialAuthorizedBrand();
  },

  mounted() {},

  methods: {
    // 获取特殊授权品牌
    getSpecialAuthorizedBrand() {
      brandLicenseListSpecialBrand().then(res => {
        this.specialBrandList = res.data || [];
      });
    },
    onDoubt() {
      const message =
        '大贸授权书：只能选择已签合同的公司/个人，且合同需要在生效中；' +
        `<br/>` +
        '跨境授权书：可以选择所有已有的主体，没有合同必须提供承诺函。';
      this.$alert(message, '提示', {
        dangerouslyUseHTMLString: true
      });
    },
    getDist(key, merchantType) {
      const { tradeType = '', brandId = '', otherValue = '' } = this.current;
      const distributorId = this.userInfo?.distributorId || '';
      const parameter = {
        data: {
          brandId,
          authChannel: this.channelValue,
          merchantType,
          tradeType,
          distributorId,
          channel: this.channelValue,
          izAvailable: '1'
        },
        pageNo: 1,
        pageSize: 100
      };
      if (otherValue) {
        parameter.data.otherChannelInfo = otherValue;
      }
      distributorContractInfoListByAvailableByApp(parameter).then(res => {
        const list = res?.data?.list || [];
        this[key] = list.map((item, index) => {
          const baseLabel = `${
            merchantType === 'PERSONAL' ? item.contactRealName : item.company
          }`;
          let extLabel = '';
          if (item.distributorContract) {
            const contractName = item.distributorContract.contractName;
            const date = `${parseTime(
              item.distributorContract.startDate,
              '{y}-{m}-{d}'
            )}-${parseTime(item.distributorContract.endDate, '{y}-{m}-{d}')}`;
            extLabel = `（${contractName}，${date}）`;
          }
          return {
            ...item,
            value: item.id + '-' + index,
            label: extLabel ? `${baseLabel}${extLabel}` : `${baseLabel}`
          };
        });
      });
    },
    // 外部调用
    next(callback) {
      this.$refs['formName'].validate(valid => {
        if (valid) {
          const {
            current,
            otherName,
            channelValue,
            shopTypeValue
          } = this.getInitData();
          const {
            tradeType,
            brandId,
            firstPurchaseOrderId,
            firstPurchaseOrderNo
          } = current;
          const {
            hasCommitmentLetter,
            formLabelAlign,
            licenseCompany,
            taxNo
          } = this;
          const form = cloneDeep(formLabelAlign);
          // 如果当前不需要承诺函
          if (!hasCommitmentLetter) {
            delete form.commitmentLetter;
          }
          const parameter = {
            tradeType,
            brandId,
            channel: channelValue,
            shopType: shopTypeValue,
            licenseCompany,
            ...form,
            taxNo,
            firstPurchaseOrderId,
            firstPurchaseOrderNo
          };
          const { currentMainBodyInfo } = this;
          if (currentMainBodyInfo) {
            parameter.distributorContractId = currentMainBodyInfo.distributorContractId || '';
            parameter.distributorContractInfoId = currentMainBodyInfo.id || '';
          }
          if (otherName) {
            parameter.otherChannelInfo = [otherName];
          }
          if (shopTypeValue === 'SPECIAL' && !parameter.platformShopId) {
            parameter.platformShopId = '/';
          }
          licenseApplyRecordSubmit(parameter).then(() => {
            callback();
          });
        } else {
          return false;
        }
      });
    },
    changeMainBodyType() {
      this.mainBodyId = '';
      this.formLabelAlign.licenseCompany = '';
      this.formLabelAlign.mainBodyId = '';
    },
    changeLicenseCompany(vid) {
      const crs = this.licenseCompanyList.find(({ value }) => value === vid);
      this.formLabelAlign.mainBodyId = crs.id;
      this.taxNo = crs.taxNo;
      this.licenseCompany = crs.company;
    },
    // 下载承诺函模板
    downloadPromiseTemplate() {
      window.location.href = `${process.env.VUE_APP_MUSHUROOMFILEURL}/static/file/soyoung-zg/template/销售承诺函模板.docx`;
    },
    // 上传成功
    handleSuccess(res) {
      this.formLabelAlign.commitmentLetter = res.data || '';
      this.$refs['formName'].clearValidate(['commitmentLetter']);
    },
    // 清除文件
    handleRemove() {
      this.formLabelAlign.commitmentLetter = '';
    }
  }
};
</script>
<style lang="scss" scoped>
.logobox {
  text-align: center;
  max-width: 100px;

  img {
    width: 55px;
    height: 55px;
    display: block;
    margin: 0 auto;
  }
}
.my-form-box {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 90px;
  /deep/ {
    .el-select {
      width: 100%;
    }
    .el-upload {
      text-align: left;
    }
  }
  .tip {
    font-size: 12px;
    color: #666;
  }
}
.el-icon-question {
  color: #666;
  cursor: pointer;
}
</style>
