<!--pc端充值 -->
<template>
  <div>
    <slot>
      <el-button @click="rechargeModeShow" size="mini" type="primary">预付款</el-button>
    </slot>
    <el-dialog
      :before-close="handleClose"
      :visible.sync="dialogFormVisible"
      title="充值"
      width="30%"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item :label-width="formLabelWidth" label="充值金额" prop="amount">
          <el-input placeholder="请输入充值金额" v-model="form.amount"></el-input>
        </el-form-item>
        <el-form-item
          :label-width="formLabelWidth"
          label="充值账户"
          prop="rechargeType"
          style="text-align: left"
        >
          <el-radio-group v-model="form.rechargeType">
            <el-radio label="BALANCE">余额</el-radio>
            <el-radio label="DEPOSIT">保证金</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button @click="submit('ruleForm')" type="primary">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="rechargeModeDialogVisible" title="请选择充值方式" width="800px">
      <div class="dialog-rechargeMode">
        <div class="dialog-rechargeMode_tip">
          <p>大额充值，请选择线下转账方式：</p>
          <p>1、转账到水羊的官方收款账户；</p>
          <p>2、填写转账信息；</p>
          <p>3、等待财务核实，充值到账；</p>
        </div>
        <div class="dialog-rechargeMode_account">
          <span>对方收款账户：</span>
          <div
            class="dialog-rechargeMode_account--info"
            v-for="(i, index) of accountData"
            :key="index"
          >
            <div class="info-item">
              <div v-for="{ label, prop } of accountOptions" :key="prop" class="info-item__container">
                <span class="info-item__label">{{ label }}：</span>
                <span class="info-item__content">{{ i[prop] || '--' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer" slot="footer" style="text-align: center;">
        <el-button type="primary" @click="goRecharge">线下转账</el-button>
        <el-button @click="show" style="margin-left: 10px;">线上支付</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { validateMoney } from '@/common/validator';
import { create } from '@/api/pay';
import { listOwnCompanyInfo } from '@/api/shop';
export default {
  name: 'Pay',
  data() {
    return {
      dialogFormVisible: false,
      accountSubtype: '',
      companyInfoVO: {},
      form: {
        payType: '', // 支付方式
        amount: null, // 充值金额
        rechargeType: 'BALANCE', // 充值类型，默认是账户余额
      },
      postData: {}, // 创建订单，接口返回的数据
      rules: {
        amount: [
          { required: true, message: '必填信息', trigger: 'blur' },
          { validator: validateMoney, trigger: 'blur' },
        ],
        rechargeType: [{ required: true, message: '必填信息', trigger: 'blur' }],
      },
      formLabelWidth: '120px',

      rechargeModeDialogVisible: false,
      accountData: [],
    };
  },
  computed: {
    accountOptions() {
      return [
        {
          label: '户名',
          prop: 'ownCompanyName',
        },
        {
          label: '账号',
          prop: 'ownCompanyBandCardNumber',
        },
        {
          label: '银行',
          prop: 'ownCompanyBankName',
        },
      ];
    },
  },
  created() {

  },
  methods: {
    init() {
      // 获取公司账户列表
      listOwnCompanyInfo()
      .then(response => {
        this.accountData = response.data.filter(item => item.ownCompanyId === this.companyInfoVO?.ownCompanyId || '');
        this.accountSubtype = this.accountData[0].type || 'SELF_BRAND_COMPANY';
      });
    },
    // 关闭弹窗
    handleClose() {
      this.dialogFormVisible = false;
    },
    rechargeModeShow(companyInfoVO) {
      this.companyInfoVO = companyInfoVO;
      this.init();
      this.rechargeModeDialogVisible = true;
    },
    show() {
      this.rechargeModeDialogVisible = false;
      this.dialogFormVisible = true;
      this.form = {
        payType: '', // 支付方式
        amount: null, // 充值金额
        rechargeType: 'BALANCE', // 充值类型，默认是账户余额
        accountSubtype: this.accountSubtype // 充值子账户（自有仓-湖南水羊：SELF_BRAND_COMPANY,合作仓-长沙水羊：INTERNATION_BRAND_COMPANY）
      };
    },
    // 付款
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return;
        }
        create(this.form)
          .then(response => {
            if (response.code === '0') {
              this.postData = {
                payBizCode: 'INTERNATION_BRAND',
                bizType: response.data.bizType,
                body: '水羊直供充值',
                subject: '水羊直供充值',
                tenantTradeNo: response.data.id,
                tradeAmount: response.data.amount,
                payType: this.form.payType,
                accountSubtype: this.accountSubtype,
                ownCompanyId: this.companyInfoVO?.ownCompanyId || ''
              };
              sessionStorage.setItem('rechargePayData', JSON.stringify(this.postData));
              window.open(`/syoung/pay/list?type=recharge`);
              return;
            }
            this.$message.error(response.msg);
          })
          .finally(() => {
            this.dialogFormVisible = false;
            this.$refs.pay.show();
          });
      });
    },
    goRecharge() {
      this.$router.push('/syoung/shop/transfer-account-info?ownCompanyId=' + this.companyInfoVO.ownCompanyId);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  &-rechargeMode {
    &_tip {
      width: 100%;
      padding: 10px;
      background-color: #fffaf1;
      color: #e98e5e;
      font-size: 14px;
      text-align: left;
      line-height: 22px;
      margin-bottom: 16px;
    }
    &_account {
      display: flex;
      &--info {
        &:not(:first-child) {
          margin-left: 10px;
        }
        width: 400px;
        text-align: left;
        .info-title {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 10px;
          color: #303133;
        }
        .info-item {
          display: flex;
          flex-direction: column;
          border: 1px solid #ddd;
          padding: 10px;
          box-sizing: border-box;
          font-weight: 400;
          line-height: 1.5;
          &__container {
            display: flex;
            &:not(:last-child){
              margin-bottom: 6px;
            }
          }
          &__label,
          &__content {
            display: inline-flex;
            align-items: baseline;
          }
          &__label:before {
            content: '*';
            color: #f56c6c;
            margin-right: 4px;
          }
          &__content {
            flex: 1;
            font-weight: 700;
            color: #303133;
          }
        }
      }
    }
  }
}
</style>
