<!-- 我的授权详情 （ 无店 ）-->
<template>
  <div class="brand-my-detail">
    <el-button type="text" @click="onDoubt"><i class="el-icon-question">授权统计规则</i></el-button>
    <div class="brand-my-detail-head">
      <div v-for="item of brandList" :key="item.brandId" class="brandbox">
        <img class="img" :src="item.logoUrl" :alt="item.brandName" />
        <div class="name">{{ item.brandName || '品牌已删除' }}<span v-if="item.izShowTrade==='1'">({{item.tradeTypeName}})</span> </div>
      </div>
    </div>
    <div v-if="isRules" class="brand-my-detail-rules">
      <div v-for="(item, i) of thresholdList" :key="i">
        <div class="title">条件{{ (i + 1) | numberConvertToUppercase }}：{{ item.txt }}</div>
      </div>
    </div>
    <div class="brand-my-detail-body" v-if="!isRules">
      <div class="order-no">所选订单号：{{ details.firstPurchaseOrderNo }}</div>
      <div class="conditions" v-for="(item, i) of thresholdList" :key="i">
        <div class="left">
          <div class="title">条件{{ (i + 1) | numberConvertToUppercase }}：{{ item.txt }}</div>
          <div v-if="item.date">
            {{ item.date }} <span v-if="item.tip2" class="tip2">{{ item.tip2 }}</span>
          </div>
          <div v-if="item.tip">
            {{ item.tip }}
          </div>
        </div>
        <div class="right">
          <div class="conditions-purchase">
            <i :class="item.isSuccess ? 'el-icon-success' : 'el-icon-error'"></i>
            <span>{{ item.isSuccess ? '已' : '未' }}达成</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  storelessLicenseGetMyLicenseDetail,
} from '@/api/brandauthorization';
import { parseTime } from '@/utils/date';
export default {
  data() {
    return {
      brandList: [], // 品牌列表
      thresholdList: [], // 门槛列表
      details: {}, //所有数据
    };
  },
  name: 'brandMyDetailNoShop',
  components: {},
  computed: {
    isRules() {
      const { cpType } = this.YDialogInitData;
      return cpType === 'rules'; // 是否是查看规则
    },
    ruleText() {
      const { ruleText = '' } = this.YDialogInitData.licenseConfig;
      return ruleText.replace(/[(\r\n)|(\n)|(↵)]+/g, '\n');
    },
  },

  created() {
    if (!this.isRules) {
      this.getDetails();
    } else {
      this.setNoShopData();
    }
  },

  mounted() {},
  methods: {
    onDoubt() {
      this.$alert(this.ruleText);
    },
    // 达成详情
    getDetails() {
      const { channel, id, firstPurchaseOrderId, tradeType } = this.YDialogInitData;
      storelessLicenseGetMyLicenseDetail(id, channel, firstPurchaseOrderId, tradeType).then((res) => {
        const data = res.data;
        const brandName = [];
        data.storelessLicenseBrandOrderVOList.forEach((item) => {
          if (item.isFirstPurchaseAmount === '1') {
            brandName.push(item.brandName);
          }
        });
        data.brandName = brandName.join();
        data.brandNameLength = brandName.length;
        this.details = data;
        this.setNoShopData(data);
      });
    },
    // 无店
    setNoShopData(postData = {}) {
      const data = Object.assign({}, this.YDialogInitData, postData);
      const {
        brandVOList,
        firstPurchaseAmount,
        isFirstPurchaseAmount,
        firstPurchaseBrandQuantity,
        isFirstPurchaseBrandQuantity,
        storelessLicenseBrandOrderVOList, // 无店授权品牌首采门槛达成情况
        orderAmount,
        createStartDate,
        createEndDate,
        brandNameLength,
        brandName,
      } = data;
      this.brandList = [...brandVOList];
      const thresholdList = []; // 门槛列表
      if (firstPurchaseAmount > 0) {
        thresholdList.push({
          txt: `首采门槛${firstPurchaseAmount}元（单笔订单）`,
          isSuccess: isFirstPurchaseAmount === '1',
          tip: orderAmount ? `该订单首采总金额：${orderAmount}元` : '',
          date: `首采统计时间：${parseTime(createStartDate, '{y}.{m}.{d}')}-${parseTime(
            createEndDate,
            '{y}.{m}.{d}'
          )}`,
        });
      }
      if (firstPurchaseBrandQuantity > 0) {
        thresholdList.push({
          txt: `${firstPurchaseBrandQuantity}个或以上品牌达到品牌门槛`,
          isSuccess: isFirstPurchaseBrandQuantity === '1',
          tip: `该订单共有${brandName}${brandNameLength}个品牌达成`,
        });
      }
      const arr = storelessLicenseBrandOrderVOList || brandVOList;
      this.thresholdList = thresholdList.concat(
        arr.map((k) => {
          return {
            txt: `${k.brandName || '品牌已删除'} 首采门槛${k.firstPurchaseAmount}元`,
            isSuccess: k.isFirstPurchaseAmount === '1',
            tip: `${k.brandName}首采门槛${k.actualFirstPurchaseAmount}元`,
            date: `首采统计时间：${parseTime(createStartDate, '{y}.{m}.{d}')}-${parseTime(
              createEndDate,
              '{y}.{m}.{d}'
            )}`,
          };
        })
      );
    },
  },
};
</script>
<style lang='scss' scoped>
.brand-my-detail-head {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .brandbox {
    text-align: center;
    margin: 19px 15px;
    .img {
      width: 55px;
      height: 55px;
    }
    .name {
      font-size: 14px;
      color: #363636;
      margin-top: 5px;
      span{
        font-size: 12px;
      }
    }
  }
}
.brand-my-detail-body {
  min-height: 300px;
  .order-no {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
  }
  .conditions {
    display: flex;
    align-items: center;
    background: #ecf4fd;
    margin-bottom: 20px;
    border: 1px solid #ecf4fd;
    padding: 16px 13px 19px 27px;
    color: #333333;
    font-size: 14px;
    .left {
      flex: 1;
      padding-right: 20px;
      & > div {
        margin: 17px 0;
      }
      > .title {
        font-weight: bold;
      }
      .tip2 {
        font-size: 12px;
        color: #666;
        display: block;
        margin-top: 5px;
      }
    }
    .conditions-purchase {
      display: flex;
      align-items: center;
      i {
        font-size: 20px;
        margin-right: 5px;
      }

      .el-icon-success {
        color: #AB0033;;
      }
      .el-icon-error {
        color: #999999;
      }
    }
  }
}
.brand-my-detail-rules {
  padding-left: 30px;
  .title {
    margin: 19px 0;
  }
}
</style>