<!-- 申请记录 -->
<template>
  <div>
    <el-table :data="list" style="width: 100%" header-align="center" v-loading="listLoading">
      <el-table-column label="授权主体" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.mainBodyType | fsMainBodyType }}</span>
        </template></el-table-column
      >
      <el-table-column label="授权品牌" prop="brandName" align="center"> </el-table-column>
      <el-table-column prop="channelName" label="授权渠道" width="auto" align="center">
      </el-table-column>
      <el-table-column prop="platformShopName" label="授权店铺" width="auto" align="center">
      </el-table-column>
      <el-table-column label="申请时间" width="auto" align="center">
        <template slot-scope="scope">
          <span class="table__name--price">{{
            scope.row.createDate | parseTime('{y}/{m}/{d}')
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="auditStatusName" label="审核状态" width="150px" align="center">
        <template slot-scope="scope">
          <span :style="statusStyle(scope.row.auditStatus)">
            {{ scope.row.auditStatusName }}
            <el-button
              type="text"
              v-if="scope.row.auditStatus === 'REJECT'"
              @click="toDetails(scope.row.id)"
              >查看驳回原因</el-button
            >
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageNo"
        :disabled="listLoading"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        background
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  licenseApplyRecordListMine,
  licenseApplyRecordGetRejectReason,
} from '@/api/brandauthorization';
export default {
  data() {
    return {
      list: [],
      listLoading: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
    };
  },

  components: {},

  computed: {},
  filters: {
    fsMainBodyType(v) {
      const obj = {
        ENTERPRISE: '企业',
        PERSONAL: '个人',
      };
      return obj[v];
    },
  },
  created() {
    this.fetchData();
  },

  mounted() {},

  methods: {
    toDetails(id) {
      licenseApplyRecordGetRejectReason(id).then((res) => {
        this.$alert(res.data, '驳回原因', {
          confirmButtonText: '知道了',
          callback: (action) => {},
        });
      });
    },
    statusStyle(key = 'PASS') {
      const obj = {
        WAIT_AUDIT: '#FF6B43',
        PASS: '#333333',
        REJECT: '#AB0033;',
      };
      return { color: obj[key] };
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        data: {},
      };
      licenseApplyRecordListMine(listQuery)
        .then((response) => {
          const { list, total } = response.data;
          this.list = list;
          this.total = total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>
<style lang='scss' scoped>
</style>